.email_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 3% 0;
}


.contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.contacts_text {
    font-weight: bold;
    margin: 7px 0;
}

.contact_title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serifs;
    margin-bottom: 55px;
    font-size:3vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
}

.h2_contacts {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.email_text {
    display: flex;
    flex-direction: column;
}

form {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0 30px;
    margin-left: 120px;
    margin-top: 80px;
}

input {
    border: none;
    border-bottom: 1px solid #333;
    width: 100%;
    padding: 7px 0;
    margin-bottom: 10px;
    color: #A3A3A3;
}

label {
    font-size: 16px;
    margin: 15px 0;
}

textarea {
    display: flex;
    border: none;
    height: 90px;
    font-size: 14px;
    font-family: 'Gill Sans',sans-serif;
    color: #A3A3A3;
    padding-top: 30px;
    border-bottom: 1px solid #333;
    margin-bottom: 15px;
}

::placeholder {
    color: #A3A3A3;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Submit {
    border: none;
    background-color: #333;
    color: #FFF;
    padding: 17px 0;
    font-size: 20px;
}

@media only screen and (min-width: 1146px) {
    .contacts {
        width: 35%;
    }

    .user_image {
        width: 100%;
    }

    form {
        width: 35%;
    }
}

@media only screen and (max-width: 1145px)  {
    .email_container {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 10%;
        height: auto;
    }

    .h2_contacts {
        font-size: 2rem;
    }

    .contact_title {
        text-align: center;
        margin-bottom: 55px;
        font-size:3.6rem;
        font-weight: 200;
        letter-spacing: 7px;
    }

    .contacts_p {
        margin-top: 35px;
    }

    .contacts {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .email_text {
        max-width: 80%;
    }

    .user_image {
        width: 100%;
    }

    form {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    input {
        font-size: 14px;
    }

    textarea {
        font-size: 16px;
    }
}
