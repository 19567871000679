.footer_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-evenly;
    bottom: 0;
    /* padding-bottom: 40px;
    padding-right: 60px;
    padding-left: 30px; */
    background-color:#4D5D6B;
    height: 260px;
    font-size: 22px;
    width: 100%;
    padding: 40px 0 100px 0;
}

.footer_link {
    color: #fff;
    font-size: 22px;
}

.contact_email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.footer_h3 {
    display: flex;
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 200;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.om_mig_footer {
    display: flex;
    flex-direction: column;
    width: 30%;
    color: #fff;
}

.footer_p {
    font-weight: lighter;
    font-size: 0.8vw;
    margin: 0;
    padding: 0;
}

.links_footer {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 30%;
}

.footer_link:hover {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
}

.footer_contact {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 200;
    color: #fff;
    padding: 10px 0 0 0;
}


.footer_contact:hover {
    color: #fff;
}


.soc_media {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer_icon {
    font-size: 2.8rem;
    color: #fff;
    padding: 0 10px;
}

.footer_icon:hover {
    color: #fff;
    opacity: 0.8;
}

@media only screen and (max-width: 1640px) {
    .footer_p {
        font-size: 0.9vw;
    }
}

@media only screen and (max-width: 1380px) {
    .footer_p {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 1205px) {
    .footer_p {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 1145px)  {
    .footer_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: 40px 0;
    }
    
    .footer_h3 {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        display: none;
        visibility: hidden;
    }

    .footer_p {
        display: flex;
        text-align: justify;
        padding: 15px 0 30px 0;
        display: none;
        visibility: hidden;
    }

    .contact_email {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 10px 0 15px 0;
    }

    .bottom {
        padding-top: 15px;
    }

    .links_footer {
        align-items: center;
        justify-content: center;
        padding: 30px 0 0 0;
    }

    .footer_icon {
        font-size: 1.8rem;
    }
}