.notfound_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notfound_img {
    width: 400px;
    margin-bottom: 30px;
}

.notfound_link {
    font-size: 42px;
    font-weight: 600;
    margin-top: 15px;
    color: #252525;
}

.notfound_text {
    font-size: 24px;
    text-transform: uppercase;
}

