@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Libre Caslon Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 330px);  
  z-index: 0;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45vw;
  height: auto;
  margin-bottom: 160px;
}

.portfolio_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 3vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
    margin: 0 0 60px 0;
}

@media only screen and (max-width: 1030px)  {
  .carousel {
    width: 90vw;
    margin-bottom: 50px;
  }

  .portfolio_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 8vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
    margin: 3% 0;
  }
}


.notfound_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notfound_img {
    width: 400px;
    margin-bottom: 30px;
}

.notfound_link {
    font-size: 42px;
    font-weight: 600;
    margin-top: 15px;
    color: #252525;
}

.notfound_text {
    font-size: 24px;
    text-transform: uppercase;
}



/* HEADER */

header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    height: 130px;
    width: 100%;
}

nav {
    margin-top: 100px;
    margin-right: 80px;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    color: black;
    border: none;
    font-size: 1.8rem;
    visibility: hidden;
    display: none;
    opacity: 0;
}

.logo_image {
    display: flex;
    position: absolute;
    top: 10px;
    left: 42%;
    width: 280px;
}

.main {
    display: flex;
    align-items: center;
    justify-content: center;
}


a {
    color: #333;
    text-decoration: none;
    padding: 0 15px;
    font-weight: 300;
    font-size: 1vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

a:hover {
    color: #4D5D6B;
    text-decoration: underline;
}

@media only screen and (max-width: 1328px) {
    .logo_image {
        left: 42%;
        top: 25px;
        width: 200px;
    }

    a {
        font-size: 1rem;
    }
}


/* @media only screen and (max-width: 1080px) {
    header {
        height: 160px;
    }

    .logo_image {
        left: 42%;
        top: 0;
        width: 200px;
    }

    nav {
        margin-bottom: 40px;
        margin-right: 30px;
    }
} */

@media only screen and (max-width: 1110px) {
    
    header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0 20px;
        height: auto;
        width: 100%;
    }

    nav {
        margin: 0;
        padding: 0;
    }
    
    header .nav-btn {
        display: flex;
        visibility: visible;
        opacity: 1;
        font-size: 2.8rem;
    }

    header nav {
        position: fixed;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 1s;
        -webkit-transform: translateY(-120vh);
                transform: translateY(-120vh);
        background-color: #252525;
    }

    .logo_image {
        position: relative;
        width: 150px;
        padding: 0;
        left: 0;
    }

    header .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background-color: #252525;
        color: #fff;
        border-radius: 2px;
        font-size: 3rem;
        font-weight: lighter;
}

    header .responsive_nav {
        -webkit-transform: none;
                transform: none;
        position: absolute;
        top: 0;
        right: 2rem;
        width: 100%;
    }

    a {
        font-size: 1.7rem;
        padding: 15px 0;
        color: #fff;
    }

    a:hover {
        color: #fff;
        opacity: 0.8;
        text-decoration: none;
    }


}

/* @media only screen and (max-width: 1000px)  {
    header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }

    .main {
        padding-top: 30px;
    }

    .menu {
        margin-top: 30px;
        width: 80%;
    }

    a {
        font-size: 0.8rem;
        padding: 0 10px;
    }

    .logo {
        margin-top: 10px;
    }

    .soc_media_header {
        margin-top: 10px;
    }
} */
.footer_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-evenly;
    bottom: 0;
    /* padding-bottom: 40px;
    padding-right: 60px;
    padding-left: 30px; */
    background-color:#4D5D6B;
    height: 260px;
    font-size: 22px;
    width: 100%;
    padding: 40px 0 100px 0;
}

.footer_link {
    color: #fff;
    font-size: 22px;
}

.contact_email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.footer_h3 {
    display: flex;
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: 200;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.om_mig_footer {
    display: flex;
    flex-direction: column;
    width: 30%;
    color: #fff;
}

.footer_p {
    font-weight: lighter;
    font-size: 0.8vw;
    margin: 0;
    padding: 0;
}

.links_footer {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 30%;
}

.footer_link:hover {
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
}

.footer_contact {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 200;
    color: #fff;
    padding: 10px 0 0 0;
}


.footer_contact:hover {
    color: #fff;
}


.soc_media {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer_icon {
    font-size: 2.8rem;
    color: #fff;
    padding: 0 10px;
}

.footer_icon:hover {
    color: #fff;
    opacity: 0.8;
}

@media only screen and (max-width: 1640px) {
    .footer_p {
        font-size: 0.9vw;
    }
}

@media only screen and (max-width: 1380px) {
    .footer_p {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 1205px) {
    .footer_p {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 1145px)  {
    .footer_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: 40px 0;
    }
    
    .footer_h3 {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        display: none;
        visibility: hidden;
    }

    .footer_p {
        display: flex;
        text-align: justify;
        padding: 15px 0 30px 0;
        display: none;
        visibility: hidden;
    }

    .contact_email {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 10px 0 15px 0;
    }

    .bottom {
        padding-top: 15px;
    }

    .links_footer {
        align-items: center;
        justify-content: center;
        padding: 30px 0 0 0;
    }

    .footer_icon {
        font-size: 1.8rem;
    }
}

.container_homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    height: auto;
    width: 100%;
}

/* .main_img_header {
    width: 70%;
    height: 70vh;
    margin-bottom: 6%;
} */

/* OM MIG */

.om_mig {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.om_mig_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: #fff;
    width: auto;
    height: 700px;
    background-color: #4D5D6B;
    padding: 20px 40px;
}

.om_mig_p {
    font-size: 1.8vw;
    padding: 80px;
}

.om_mig_image {
    height: 100%;
    width: auto;
}


.img_ommig {
    display: flex;
    width: 1000px;
    height: 700px;
}


/* SERVICES */

.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 850px;
    padding-top: 70px;
    margin-bottom: 40px;
}


.services_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 90%;
    min-height: 300px;
    margin-bottom: 3%;
}

.services_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30%;
    min-width: 400px;
    margin: 15px;
}

.priser_list {
    margin: 50px 0 60px 0;
}

.services_main_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 3vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
}

.services_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.9vw;
    font-weight: 200;
    color: #4D5D6B;
}

.priser_h3 {
    margin: 20px 0 20px 0;
}

.services_p {
    font-size: 0.9vw;
    padding: 3px 0;
}

.services_description {
    text-align: center;
    padding: 30px;
}

.services_icon {
    width: 75px;
    margin: 15px 0;
}

span {
    font-style: italic;
}


.background_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url(/static/media/nature.c0ca9fbe.jpg) center / cover no-repeat fixed;
    color: #fff;
    height: 80vh;
    width: 100%;
    margin-bottom: 1%;
}

.contacts_btn {
    padding: 20px 35px;
    background-color: #fff;
    color: #252525;
    font-weight: 400;
    font-size: 20px;
    border: none;
    border-radius: 2px;
}

.contacts_btn:hover {
    cursor: pointer;
    color: #4D5D6B;
}


.background_image_text {
    position: absolute;
    text-align: center;
    font-size: 3.2vw;
    font-weight: 400;
    color: #fff;
    width: 70%;
}


@media only screen and (max-width: 1780px)  {
    .img_ommig {
        width: 816px;
        height: 600px;
    }

    .om_mig_text {
        height: 600px;
    }

     .services_p {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 1495px)  {
    .om_mig_p {
        font-size: 1.2rem;
    }

    .om_mig_text {
        height: 500px;
    }

     .img_ommig {
        width: 680px;
        height: 500px;
    }

}

@media only screen and (max-width: 1290px)  {
    .img_ommig {
        width: 585px;
        height: 430px;
    }

    .om_mig_text {
        height: 430px;
    }

    .om_mig_p {
        font-size: 1.3rem;
    }

}


@media only screen and (max-width: 1180px)  {
    .om_mig {
        height: auto;
        width: 100%;
    }

    .om_mig_p {
        font-size: 1.2rem;
        padding: 30px;
    }

     .services_p {
        font-size: 1.4vw;
    }

}



@media only screen and (max-width: 1030px) {
    .container_homepage {
        margin-top: 1%;
    }

    .om_mig {
        flex-direction: column-reverse;
        height: auto;
        width: 100%;
        margin-top: 10%;
    }
    
    .om_mig_text {
        width: 90%;
        text-align: left;
        height: auto;
        padding: 30px 10px;
    }

    .om_mig_i,
    .om_mig_p {
        width: 100%;
        font-size: 1.4rem;
    }

    .om_mig_image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
    }

    .img_ommig {
        width: 90%;
        height: auto;
    }

    .services {
        padding-top: 20px;
        padding-bottom: 0;
    }

    .services,
    .services_list,
    .services_list_item {
        width: 100%;
    }

    .priser_list {
        margin: 30px;
    }

    .services_main_h3 {
        font-size: 8vw;
    } 

    .services_h3 {
        font-size: 6vw;
    }
    
    .services_p {
        font-size: 2vw;
        width: 80%;
    }

    .background_image {
        height: 38vh;
        background: url(/static/media/nature.c0ca9fbe.jpg) center / cover no-repeat;
    }



    .background_image_text {
        font-size: 4vw;
    }

    .contacts_btn {
        padding: 10px 18px;
    }
}

@media only screen and (max-width: 864px) {
    
    .services_p {
        font-size: 2.7vw;
    }
}

@media only screen and (max-width: 560px) {
    
    .services_p {
        font-size:3.2vw;
    }
}
    

.container_app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 10% 0;
}

.line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 5%;
    margin-bottom: 10%;
}

.processen_img {
    display: flex;
    height:auto;
    width: 55%;
    min-width: 350px;
    z-index: -1;
    margin: 50px 0;
}

.one {
    display: none;
    visibility: hidden;
}

.processen_text {
    font-family: 'Libre Caslon Text', serif;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 45%;
    padding: 10px 40px;
}

.processen_h3 {
    text-align: left;
    margin-top: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.8vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
}

.processen_subtitle {
    text-align: left;
    margin-bottom: 0;
    margin-top: 15px;
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.2vw;
    font-weight: 200;
    color: #4D5D6B;
}

.processen_bold {
    padding: 0;
    margin: 5px 0;
}

.processen_italic {
    font-style: italic;
    padding: 0;
    margin: 0;
}

.processen_text {
    font-size: 1rem;
}

.processen_p {
    display: flex;
    text-align: justify;
}

@media only screen and (min-width: 1340px) {
    .line {
        width: 80%;
    }
}

@media only screen and (max-width: 1245px)  {
    
    .line {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10%;
    }

    .processen_text {
        display: flex;
        margin: 35px 0;
        width: 100%;
    }

    .processen_img {
        width: 90%;
        height: auto;
        margin: 15px 0;
    }

    .processen_h3 {
        margin-top: 25px;
        font-size: 2rem;
    }

    .processen_subtitle {
        font-size: 1.6rem;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .processen_p {
        width: 100%;
        margin-bottom: 20px;
    }

    .processen_bold {
        margin: 5px 0 20px 0;
    }

    .one {
        display: flex;
        position: relative;
        visibility: visible;
        height: auto;
        margin: 20px 0;
    }
    
    .two {
        display: none;
        visibility: hidden;
    }

}


.email_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 3% 0;
}


.contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.contacts_text {
    font-weight: bold;
    margin: 7px 0;
}

.contact_title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serifs;
    margin-bottom: 55px;
    font-size:3vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
}

.h2_contacts {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.email_text {
    display: flex;
    flex-direction: column;
}

form {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0 30px;
    margin-left: 120px;
    margin-top: 80px;
}

input {
    border: none;
    border-bottom: 1px solid #333;
    width: 100%;
    padding: 7px 0;
    margin-bottom: 10px;
    color: #A3A3A3;
}

label {
    font-size: 16px;
    margin: 15px 0;
}

textarea {
    display: flex;
    border: none;
    height: 90px;
    font-size: 14px;
    font-family: 'Gill Sans',sans-serif;
    color: #A3A3A3;
    padding-top: 30px;
    border-bottom: 1px solid #333;
    margin-bottom: 15px;
}

::-webkit-input-placeholder {
    color: #A3A3A3;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

::placeholder {
    color: #A3A3A3;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Submit {
    border: none;
    background-color: #333;
    color: #FFF;
    padding: 17px 0;
    font-size: 20px;
}

@media only screen and (min-width: 1146px) {
    .contacts {
        width: 35%;
    }

    .user_image {
        width: 100%;
    }

    form {
        width: 35%;
    }
}

@media only screen and (max-width: 1145px)  {
    .email_container {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 10%;
        height: auto;
    }

    .h2_contacts {
        font-size: 2rem;
    }

    .contact_title {
        text-align: center;
        margin-bottom: 55px;
        font-size:3.6rem;
        font-weight: 200;
        letter-spacing: 7px;
    }

    .contacts_p {
        margin-top: 35px;
    }

    .contacts {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .email_text {
        max-width: 80%;
    }

    .user_image {
        width: 100%;
    }

    form {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    input {
        font-size: 14px;
    }

    textarea {
        font-size: 16px;
    }
}

.services_container_homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    height: auto;
    width: 100%;
}

/* OM MIG */

.services_om_mig {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 70vh;
}

.services_om_mig_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: justify;
    color: #252525;
    width: 50%;
    height: 100%;
    font-size: 20px;
}

.services_om_mig_image {
    width: 50%;
    height: auto;
}

.services_om_mig_h2 {
    font-size: 5rem;
    font-weight: 400;
    margin: 0 0 25px 0;
}

.services_om_mig_i {
    font-style: italic;
    margin: 0 70px 15px 0;
}

.services_om_mig_p {
    margin: 0 70px 0 0;
}

.services_img_ommig {
    display: flex;
    width: 100%;
    height: 100%;
}


/* SERVICES */

.services_services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 450px;
    padding-top: 2%;
}

.prices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color:#4D5D6B;
    margin-bottom: 5%;
    height: 100vh;
}

.prices_services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 450px;
    padding-top: 2%;
    color: #fff;
}

.prices_services_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 300px;
    margin: 15px;
    color: #fff;
}

.prices_services_main_h3 {
    font-size:2.5rem;
}

.prices_services_icon {
    width: 75px;
    margin: 15px 0;
}

.prices_services_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 120px;
    width: 70%;
}


.services_services_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 120px;
    width: 70%;
}

.services_services_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 300px;
    margin: 15px;
}

.services_services_main_h3 {
    font-size:2.5rem;
}

.services_services_icon {
    width: 75px;
    margin: 15px 0;
}


.services_background_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color:#4d5d6b; */
    position: relative;
    color: #fff;
    height: 450px;
    width:100%;
}

.services_contacts_btn {
    padding: 20px 35px;
    background-color: #fff;
    color: #252525;
    font-weight: 400;
    font-size: 20px;
    border: none;
    border-radius: 2px;
}

.services_contacts_btn:hover {
    cursor: pointer;
    color: #4D5D6B;
}

.services_image_nature {
    display: flex;
    height: 700px;
    width: 100%;
    position: relative;
}

.services_background_image_text {
    position: absolute;
    text-align: center;
    font-size: 48px;
    font-weight: 400;
    color: #fff;
    width: 70%;
}



@media only screen and (max-width: 1145px)  {

    .prices {
        height: auto;
    }

    .services_container_homepage {
        margin-top: 2%;
    }

    .services_om_mig {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        margin-top: 20%;
    }

    .services_om_mig_h2 {
        margin: 0;
        padding: 20px 0;
    }
    
    .services_om_mig_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        min-height: 450px;
        margin: 0;
    }

    .services_om_mig_i,
    .services_om_mig_p {
        width: 85%;
    }

    .services_om_mig_image {
        width: 80%;
        height: auto;
    }

    .services_services,
    .services_services_list,
    .services_services_list_item {
        width: 100%;
        margin-top: 15px;
    }

    .services_services_h3 {
        font-size: 1.3rem;
    }

    .services_services_p {
        font-size: 20px;
        text-align: justify;
        width: 80%;
    }

    .services_background_image_text {
        font-size: 1.9rem;
    }

    .services_contacts_btn {
        padding: 10px 18px;
    }

}
.Carousel {
    width: 95%;
    margin-bottom: 5%;
}

.fUcWbd {
    height: 70vh !important;
}


@media only screen and (max-width: 1110px) {
    .Carousel {
        width: 90%;
        margin-top: 20px;
    }

    .fUcWbd {
    height: 50vh !important;
    }
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    height: 70vh;
    background-color:#333;
    color: aliceblue;
    font-size: 30px;
    margin-bottom: 4%;
}

.card_image {
    display: flex;
    width: 100%;
    height: 70vh;
}

.goodpl {
    background-color:#7d8994 !important;
    box-shadow: rgb(77, 93, 107, 0.5) 0px 0px 1px 3px !important;
}

.hGPzOw {
    background-color:#7d8994 !important;
}

.hGRYOm:hover:enabled, 
.hGRYOm:focus:enabled {
    background-color:#4D5D6B !important;
    color: #fff !important;
}

.hGPzOw:disabled {
    color: #fff !important;
}

.hGRYOm {
    background-color: #fff !important;
    color: #4D5D6B !important;
} 


.Slxdj{
    background-color: #4D5D6B !important;
    color: #fff !important;
}

.kXteup {
    background-color: #fff !important;
    color: #4D5D6B !important;
}

.cVXxbE {
    box-shadow: none !important;
    background: #fff !important;
    color: #4D5D6B !important;
}

.cVXxbE:hover, .cVXxbE:focus {
    box-shadow: rgb(77, 93, 107) !important;
    background: #fff !important;
    border: #4D5D6B !important;
}

.ezKHIn {
    display: none !important;
    visibility: hidden !important;
}


/* @media only screen and (max-width: 1300px)  {
    .card {
        height: 45vh;
    }
}

@media only screen and (min-width: 1000px)  {
    .card {
        height: 50vh;
    }
} */


@media only screen and (max-width: 1110px) {
    .card {
        height: 60vh;
    }

    .card_image {
    display: flex;
    width: 100%;
    height: auto;
    }

    .Slxdj {
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        font-size: 1.8vw !important;
        line-height: none !important;
        background-color:#4D5D6B !important;
    }

    .kXteup  {
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        font-size: 1.8vw !important;
        line-height: none !important;
    }

    .gnDOZy {
        display: none !important;
    }

    .hGRYOm {
        display: none !important;
    }

    .hGPzOw:disabled {
        display: none !important;
    }

}

@media only screen and (max-width: 820px) {
    .card {
        height: 45vh;
    }
}

@media only screen and (max-width: 680px) {
    .card {
        height: 35vh;
    }
}

@media only screen and (max-width: 520px) {
     .card {
        height: 25vh;
    }
}
