.container_app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 10% 0;
}

.line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 5%;
    margin-bottom: 10%;
}

.processen_img {
    display: flex;
    height:auto;
    width: 55%;
    min-width: 350px;
    z-index: -1;
    margin: 50px 0;
}

.one {
    display: none;
    visibility: hidden;
}

.processen_text {
    font-family: 'Libre Caslon Text', serif;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 45%;
    padding: 10px 40px;
}

.processen_h3 {
    text-align: left;
    margin-top: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.8vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
}

.processen_subtitle {
    text-align: left;
    margin-bottom: 0;
    margin-top: 15px;
    padding: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.2vw;
    font-weight: 200;
    color: #4D5D6B;
}

.processen_bold {
    padding: 0;
    margin: 5px 0;
}

.processen_italic {
    font-style: italic;
    padding: 0;
    margin: 0;
}

.processen_text {
    font-size: 1rem;
}

.processen_p {
    display: flex;
    text-align: justify;
}

@media only screen and (min-width: 1340px) {
    .line {
        width: 80%;
    }
}

@media only screen and (max-width: 1245px)  {
    
    .line {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10%;
    }

    .processen_text {
        display: flex;
        margin: 35px 0;
        width: 100%;
    }

    .processen_img {
        width: 90%;
        height: auto;
        margin: 15px 0;
    }

    .processen_h3 {
        margin-top: 25px;
        font-size: 2rem;
    }

    .processen_subtitle {
        font-size: 1.6rem;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .processen_p {
        width: 100%;
        margin-bottom: 20px;
    }

    .processen_bold {
        margin: 5px 0 20px 0;
    }

    .one {
        display: flex;
        position: relative;
        visibility: visible;
        height: auto;
        margin: 20px 0;
    }
    
    .two {
        display: none;
        visibility: hidden;
    }

}

