
.container_homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    height: auto;
    width: 100%;
}

/* .main_img_header {
    width: 70%;
    height: 70vh;
    margin-bottom: 6%;
} */

/* OM MIG */

.om_mig {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.om_mig_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: #fff;
    width: auto;
    height: 700px;
    background-color: #4D5D6B;
    padding: 20px 40px;
}

.om_mig_p {
    font-size: 1.8vw;
    padding: 80px;
}

.om_mig_image {
    height: 100%;
    width: auto;
}


.img_ommig {
    display: flex;
    width: 1000px;
    height: 700px;
}


/* SERVICES */

.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 850px;
    padding-top: 70px;
    margin-bottom: 40px;
}


.services_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 90%;
    min-height: 300px;
    margin-bottom: 3%;
}

.services_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30%;
    min-width: 400px;
    margin: 15px;
}

.priser_list {
    margin: 50px 0 60px 0;
}

.services_main_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 3vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
}

.services_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.9vw;
    font-weight: 200;
    color: #4D5D6B;
}

.priser_h3 {
    margin: 20px 0 20px 0;
}

.services_p {
    font-size: 0.9vw;
    padding: 3px 0;
}

.services_description {
    text-align: center;
    padding: 30px;
}

.services_icon {
    width: 75px;
    margin: 15px 0;
}

span {
    font-style: italic;
}


.background_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url("../nature.jpg") center / cover no-repeat fixed;
    color: #fff;
    height: 80vh;
    width: 100%;
    margin-bottom: 1%;
}

.contacts_btn {
    padding: 20px 35px;
    background-color: #fff;
    color: #252525;
    font-weight: 400;
    font-size: 20px;
    border: none;
    border-radius: 2px;
}

.contacts_btn:hover {
    cursor: pointer;
    color: #4D5D6B;
}


.background_image_text {
    position: absolute;
    text-align: center;
    font-size: 3.2vw;
    font-weight: 400;
    color: #fff;
    width: 70%;
}


@media only screen and (max-width: 1780px)  {
    .img_ommig {
        width: 816px;
        height: 600px;
    }

    .om_mig_text {
        height: 600px;
    }

     .services_p {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 1495px)  {
    .om_mig_p {
        font-size: 1.2rem;
    }

    .om_mig_text {
        height: 500px;
    }

     .img_ommig {
        width: 680px;
        height: 500px;
    }

}

@media only screen and (max-width: 1290px)  {
    .img_ommig {
        width: 585px;
        height: 430px;
    }

    .om_mig_text {
        height: 430px;
    }

    .om_mig_p {
        font-size: 1.3rem;
    }

}


@media only screen and (max-width: 1180px)  {
    .om_mig {
        height: auto;
        width: 100%;
    }

    .om_mig_p {
        font-size: 1.2rem;
        padding: 30px;
    }

     .services_p {
        font-size: 1.4vw;
    }

}



@media only screen and (max-width: 1030px) {
    .container_homepage {
        margin-top: 1%;
    }

    .om_mig {
        flex-direction: column-reverse;
        height: auto;
        width: 100%;
        margin-top: 10%;
    }
    
    .om_mig_text {
        width: 90%;
        text-align: left;
        height: auto;
        padding: 30px 10px;
    }

    .om_mig_i,
    .om_mig_p {
        width: 100%;
        font-size: 1.4rem;
    }

    .om_mig_image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
    }

    .img_ommig {
        width: 90%;
        height: auto;
    }

    .services {
        padding-top: 20px;
        padding-bottom: 0;
    }

    .services,
    .services_list,
    .services_list_item {
        width: 100%;
    }

    .priser_list {
        margin: 30px;
    }

    .services_main_h3 {
        font-size: 8vw;
    } 

    .services_h3 {
        font-size: 6vw;
    }
    
    .services_p {
        font-size: 2vw;
        width: 80%;
    }

    .background_image {
        height: 38vh;
        background: url("../nature.jpg") center / cover no-repeat;
    }



    .background_image_text {
        font-size: 4vw;
    }

    .contacts_btn {
        padding: 10px 18px;
    }
}

@media only screen and (max-width: 864px) {
    
    .services_p {
        font-size: 2.7vw;
    }
}

@media only screen and (max-width: 560px) {
    
    .services_p {
        font-size:3.2vw;
    }
}
    
