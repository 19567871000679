.services_container_homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    height: auto;
    width: 100%;
}

/* OM MIG */

.services_om_mig {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 70vh;
}

.services_om_mig_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: justify;
    color: #252525;
    width: 50%;
    height: 100%;
    font-size: 20px;
}

.services_om_mig_image {
    width: 50%;
    height: auto;
}

.services_om_mig_h2 {
    font-size: 5rem;
    font-weight: 400;
    margin: 0 0 25px 0;
}

.services_om_mig_i {
    font-style: italic;
    margin: 0 70px 15px 0;
}

.services_om_mig_p {
    margin: 0 70px 0 0;
}

.services_img_ommig {
    display: flex;
    width: 100%;
    height: 100%;
}


/* SERVICES */

.services_services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 450px;
    padding-top: 2%;
}

.prices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color:#4D5D6B;
    margin-bottom: 5%;
    height: 100vh;
}

.prices_services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 450px;
    padding-top: 2%;
    color: #fff;
}

.prices_services_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 300px;
    margin: 15px;
    color: #fff;
}

.prices_services_main_h3 {
    font-size:2.5rem;
}

.prices_services_icon {
    width: 75px;
    margin: 15px 0;
}

.prices_services_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 120px;
    width: 70%;
}


.services_services_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 60px;
    margin-bottom: 120px;
    width: 70%;
}

.services_services_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 300px;
    margin: 15px;
}

.services_services_main_h3 {
    font-size:2.5rem;
}

.services_services_icon {
    width: 75px;
    margin: 15px 0;
}


.services_background_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color:#4d5d6b; */
    position: relative;
    color: #fff;
    height: 450px;
    width:100%;
}

.services_contacts_btn {
    padding: 20px 35px;
    background-color: #fff;
    color: #252525;
    font-weight: 400;
    font-size: 20px;
    border: none;
    border-radius: 2px;
}

.services_contacts_btn:hover {
    cursor: pointer;
    color: #4D5D6B;
}

.services_image_nature {
    display: flex;
    height: 700px;
    width: 100%;
    position: relative;
}

.services_background_image_text {
    position: absolute;
    text-align: center;
    font-size: 48px;
    font-weight: 400;
    color: #fff;
    width: 70%;
}



@media only screen and (max-width: 1145px)  {

    .prices {
        height: auto;
    }

    .services_container_homepage {
        margin-top: 2%;
    }

    .services_om_mig {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        margin-top: 20%;
    }

    .services_om_mig_h2 {
        margin: 0;
        padding: 20px 0;
    }
    
    .services_om_mig_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        min-height: 450px;
        margin: 0;
    }

    .services_om_mig_i,
    .services_om_mig_p {
        width: 85%;
    }

    .services_om_mig_image {
        width: 80%;
        height: auto;
    }

    .services_services,
    .services_services_list,
    .services_services_list_item {
        width: 100%;
        margin-top: 15px;
    }

    .services_services_h3 {
        font-size: 1.3rem;
    }

    .services_services_p {
        font-size: 20px;
        text-align: justify;
        width: 80%;
    }

    .services_background_image_text {
        font-size: 1.9rem;
    }

    .services_contacts_btn {
        padding: 10px 18px;
    }

}