@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');

* {
  box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Libre Caslon Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 330px);  
  z-index: 0;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45vw;
  height: auto;
  margin-bottom: 160px;
}

.portfolio_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 3vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
    margin: 0 0 60px 0;
}

@media only screen and (max-width: 1030px)  {
  .carousel {
    width: 90vw;
    margin-bottom: 50px;
  }

  .portfolio_h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 8vw;
    font-weight: 200;
    letter-spacing: 7px;
    color: #4D5D6B;
    margin: 3% 0;
  }
}

