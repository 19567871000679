
/* HEADER */

header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    height: 130px;
    width: 100%;
}

nav {
    margin-top: 100px;
    margin-right: 80px;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background-color: #fff;
    color: black;
    border: none;
    font-size: 1.8rem;
    visibility: hidden;
    display: none;
    opacity: 0;
}

.logo_image {
    display: flex;
    position: absolute;
    top: 10px;
    left: 42%;
    width: 280px;
}

.main {
    display: flex;
    align-items: center;
    justify-content: center;
}


a {
    color: #333;
    text-decoration: none;
    padding: 0 15px;
    font-weight: 300;
    font-size: 1vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

a:hover {
    color: #4D5D6B;
    text-decoration: underline;
}

@media only screen and (max-width: 1328px) {
    .logo_image {
        left: 42%;
        top: 25px;
        width: 200px;
    }

    a {
        font-size: 1rem;
    }
}


/* @media only screen and (max-width: 1080px) {
    header {
        height: 160px;
    }

    .logo_image {
        left: 42%;
        top: 0;
        width: 200px;
    }

    nav {
        margin-bottom: 40px;
        margin-right: 30px;
    }
} */

@media only screen and (max-width: 1110px) {
    
    header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0 20px;
        height: auto;
        width: 100%;
    }

    nav {
        margin: 0;
        padding: 0;
    }
    
    header .nav-btn {
        display: flex;
        visibility: visible;
        opacity: 1;
        font-size: 2.8rem;
    }

    header nav {
        position: fixed;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 1s;
        transform: translateY(-120vh);
        background-color: #252525;
    }

    .logo_image {
        position: relative;
        width: 150px;
        padding: 0;
        left: 0;
    }

    header .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background-color: #252525;
        color: #fff;
        border-radius: 2px;
        font-size: 3rem;
        font-weight: lighter;
}

    header .responsive_nav {
        transform: none;
        position: absolute;
        top: 0;
        right: 2rem;
        width: 100%;
    }

    a {
        font-size: 1.7rem;
        padding: 15px 0;
        color: #fff;
    }

    a:hover {
        color: #fff;
        opacity: 0.8;
        text-decoration: none;
    }


}

/* @media only screen and (max-width: 1000px)  {
    header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }

    .main {
        padding-top: 30px;
    }

    .menu {
        margin-top: 30px;
        width: 80%;
    }

    a {
        font-size: 0.8rem;
        padding: 0 10px;
    }

    .logo {
        margin-top: 10px;
    }

    .soc_media_header {
        margin-top: 10px;
    }
} */