.Carousel {
    width: 95%;
    margin-bottom: 5%;
}

.fUcWbd {
    height: 70vh !important;
}


@media only screen and (max-width: 1110px) {
    .Carousel {
        width: 90%;
        margin-top: 20px;
    }

    .fUcWbd {
    height: 50vh !important;
    }
}
