.card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    height: 70vh;
    background-color:#333;
    color: aliceblue;
    font-size: 30px;
    margin-bottom: 4%;
}

.card_image {
    display: flex;
    width: 100%;
    height: 70vh;
}

.goodpl {
    background-color:#7d8994 !important;
    box-shadow: rgb(77, 93, 107, 0.5) 0px 0px 1px 3px !important;
}

.hGPzOw {
    background-color:#7d8994 !important;
}

.hGRYOm:hover:enabled, 
.hGRYOm:focus:enabled {
    background-color:#4D5D6B !important;
    color: #fff !important;
}

.hGPzOw:disabled {
    color: #fff !important;
}

.hGRYOm {
    background-color: #fff !important;
    color: #4D5D6B !important;
} 


.Slxdj{
    background-color: #4D5D6B !important;
    color: #fff !important;
}

.kXteup {
    background-color: #fff !important;
    color: #4D5D6B !important;
}

.cVXxbE {
    box-shadow: none !important;
    background: #fff !important;
    color: #4D5D6B !important;
}

.cVXxbE:hover, .cVXxbE:focus {
    box-shadow: rgb(77, 93, 107) !important;
    background: #fff !important;
    border: #4D5D6B !important;
}

.ezKHIn {
    display: none !important;
    visibility: hidden !important;
}


/* @media only screen and (max-width: 1300px)  {
    .card {
        height: 45vh;
    }
}

@media only screen and (min-width: 1000px)  {
    .card {
        height: 50vh;
    }
} */


@media only screen and (max-width: 1110px) {
    .card {
        height: 60vh;
    }

    .card_image {
    display: flex;
    width: 100%;
    height: auto;
    }

    .Slxdj {
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        font-size: 1.8vw !important;
        line-height: none !important;
        background-color:#4D5D6B !important;
    }

    .kXteup  {
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        font-size: 1.8vw !important;
        line-height: none !important;
    }

    .gnDOZy {
        display: none !important;
    }

    .hGRYOm {
        display: none !important;
    }

    .hGPzOw:disabled {
        display: none !important;
    }

}

@media only screen and (max-width: 820px) {
    .card {
        height: 45vh;
    }
}

@media only screen and (max-width: 680px) {
    .card {
        height: 35vh;
    }
}

@media only screen and (max-width: 520px) {
     .card {
        height: 25vh;
    }
}